import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaPlus, FaSchool, FaMoneyBillWave, FaMoneyBill } from 'react-icons/fa';
import { FiDelete, FiEdit, FiEye, FiTrendingDown, FiTrendingUp } from 'react-icons/fi';
import { Input, Button, Form, Table, Divider, Modal, Col, Select, Spin, Card, Statistic, Row, Tag, Descriptions, notification, Steps, Result } from 'antd';
import { parse, set } from 'date-fns';
import { ca, tr } from 'date-fns/locale';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { getExpense, getExpenseParams, getExpenses, getFundRequest, getSupplies, getUnitBalance } from '../../API/StaticGetters';
import ExpenseDetail from '../../Components/despesa/detail';
import ReactToPrint from 'react-to-print';
import ModeloRequisicao from '../../Components/requisicao/modelo';
import { ComponentToPrint } from '../../Components/requisicao/print';

const { Option } = Select;

function TransferirDespesa() {
    const navigate = useNavigate();
    const location = useLocation();
    const docRef = useRef()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [dataHist, setDataHist] = useState()
    const [form] = Form.useForm();
    const [modalApprove, setModalApprove] = useState(false)
    const [modalView, setModalView] = useState(false)
    const [typeForm, setTypeForm] = useState('')
    const [balance, setBalance] = useState()
    const [expense, setExpense] = useState()
    const [message, setMessage] = useState('')
    const [method, setMethod] = useState('')
    const [process, setProcess] = useState()
    const [currentStep, setCurrentStep] = useState(0)
    const [selectedMenu, setSelectedMenu] = useState('wait')

    useEffect(() => {
        localStorage.setItem('title', 'Transferencia de Despesas')
        localStorage.setItem('type', '2');
        getData(2);
    }, [])

    const getData = async (type) => {
        setLoading(true)
        let exps = await getFundRequest(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id, 1)
        let bnl = await getUnitBalance(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id)
        setBalance(bnl)
        setData(exps)
        setLoading(false)
    }

    const getDataHist = async (type) => {
        setLoading(true)
        let exps = await getFundRequest(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id, 'All')
        let bnl = await getUnitBalance(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id)
        setBalance(bnl)
        setDataHist(exps)
        setLoading(false)
    }

    //Colunas da Tabela
    const columnsTable = [
        { title: 'Referencia', dataIndex: 'reference', align: 'left', width: 100, key: 'id', render: (text, record) => record?.expense?.reference },
        { title: 'Rubrica', dataIndex: 'expense_type', align: 'left', width: 80, render: (text, record) => record?.expense?.expense?.codigo, key: 'expense_type' },
        { title: 'Descrição', dataIndex: 'expense_type', align: 'left', render: (text, record) => record?.expense?.expense?.nome, key: 'expense_type' },
        { title: 'Data', dataIndex: 'conta', align: 'left', render: (text, record) => dayjs(record.created_at).format('DD/MM/YYYY'), width: 80, key: 'data' },
        { title: 'Valor', dataIndex: 'Valor', align: 'left', render: (text, record) => numeral(record?.expense?.value).format('0,0.00') + ' MZN', width: 150, key: 'valor' },
        { title: 'Status', dataIndex: 'status', align: 'left', key: 'status', width: 120, render: (text, record) => <Tag color={record.status === 'Reprovado' ? 'red' : 'blue'}>{record.status.name}</Tag> },
        {
            title: 'Actions', align: 'left', width: 80, render: (text, record) =>
                <div className='flex gap-2'>
                    <Button style={{ backgroundColor: 'blue' }} className='text-white' size='small' icon={<FiEye />} onClick={() => goSee(record)}></Button>
                    <Button style={{ backgroundColor: '#047d4b' }} className='text-white' size='small' icon={<FiEdit />} onClick={() => goWork(record)}></Button>
                </div>
        }
    ];

    const columnsTableHist = [
        { title: 'Referencia', dataIndex: 'reference', align: 'left', width: 100, key: 'id', render: (text, record) => record?.expense?.reference },
        { title: 'Rubrica', dataIndex: 'expense_type', align: 'left', width: 80, render: (text, record) => record?.expense?.expense?.codigo, key: 'expense_type' },
        { title: 'Descrição', dataIndex: 'expense_type', align: 'left', render: (text, record) => record?.expense?.expense?.nome, key: 'expense_type' },
        { title: 'Data', dataIndex: 'conta', align: 'left', render: (text, record) => dayjs(record.created_at).format('DD/MM/YYYY'), width: 80, key: 'data' },
        { title: 'Valor', dataIndex: 'Valor', align: 'left', render: (text, record) => numeral(record?.expense?.value).format('0,0.00') + ' MZN', width: 150, key: 'valor' },
        { title: 'Status', dataIndex: 'status', align: 'left', key: 'status', width: 120, render: (text, record) => <Tag color={record.status === 'Reprovado' ? 'red' : 'blue'}>{record.status.name}</Tag> },
        {
            title: 'Actions', align: 'left', width: 80, render: (text, record) =>
                <div className='flex gap-2'>
                    <Button style={{ backgroundColor: 'blue' }} className='text-white' size='small' icon={<FiEye />} onClick={() => goSee(record)}></Button>
                    <Button style={{ backgroundColor: '#047d4b' }} className='text-white' size='small' icon={<FiEdit />} onClick={() => goEdit(record)}></Button>
                </div>
        }
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };


    //handles
    const goSee = async (record) => {
        let data = await getExpense(record.expense.id)
        setExpense(data)
        setModalView(true)
    }

    const goWork = async (record) => {
        setProcess()
        setCurrentStep(0)
        setTypeForm('Transferir')
        form.resetFields()
        form.setFieldsValue({
            banco: record?.expense?.supply?.bank,
            numero: record?.expense?.supply?.account
        })
        setMethod(null)
        setExpense(record)
        setModalApprove(true)
    }

    const goEdit = async (record) => {
        setProcess()
        setCurrentStep(0)
        setTypeForm('Editar')
        form.resetFields()
        form.setFieldsValue({
            banco: record?.bank,
            numero: record?.num,
            method: record?.type
        })
        setMethod(record.type)
        setExpense(record)
        setProcess(record)
        setModalApprove(true)
    }

    const handleSubmit = async () => {
        setLoading(true)
        await axios.put(
            localStorage.getItem('url') + '/api/expense/fundrequest/',
            {
                'id': expense?.id,
                'bank': form.getFieldValue('banco'),
                'num': form.getFieldValue('numero'),
                'type': method,
                'status': 7
            },
            {
                headers: {
                    'Authorization': `Token ${sessionStorage.getItem('token')}`
                },
            }
        ).then(res => {
            notification.success({
                message: 'Despesa Transferida com Sucesso'
            })
            if(typeForm=='Transferir'){
                getData()
            } else if(typeForm =='Editar'){
                getDataHist()
            }
            setProcess(res.data)
            setCurrentStep(currentStep + 1)
        }).catch(err => {
            console.log(err.response)
            notification.error({
                message: err.response.data.message
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleMenu = (type) => {
        setSelectedMenu(type)
        if (type == 'wait') {
            getData()
        } else {
            getDataHist()
        }
    }


    return (
        <div className='flex flex-col gap-4'>
            <Spin spinning={loading}>
                <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
                    <div className='menu-bar mt-2'>
                        <div className={`menu-bar-item ${selectedMenu === 'wait' ? 'active' : ''}`} onClick={() => handleMenu('wait')}>
                            <p className='menu-bar-item-text'>Processos em espera</p>
                        </div>
                        <div className={`menu-bar-item ${selectedMenu === 'hist' ? 'active' : ''}`} onClick={() => handleMenu('hist')}>
                            <p className='menu-bar-item-text'>Histórico</p>
                        </div>
                    </div>
                    <div className='w-full flex flex-wrap items-center mb-2 gap-5 justify-between grid-cols-3'>
                        <Col span={8} className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Unidade"
                                    value={balance?.unit?.name}
                                    valueStyle={{
                                        color: 'green',
                                    }}
                                    prefix={<FaSchool />}
                                />
                            </Card>
                        </Col>
                        <Col className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Saldo Disponível"
                                    value={balance?.balance}
                                    valueStyle={{
                                        color: 'green',
                                    }}
                                    suffix="MZN"
                                    prefix={<FaMoneyBillWave />}
                                />
                            </Card>
                        </Col>
                        <Col className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Saldo Pedente"
                                    value={balance?.pending}
                                    valueStyle={{
                                        color: 'BLUE',
                                    }}
                                    suffix="MZN"
                                    prefix={<FaMoneyBill />}
                                />
                            </Card>
                        </Col>
                    </div>
                    <Divider className='my-0' />
                    {selectedMenu == 'wait' &&
                        <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                            <Table
                                size='small'
                                className='custom-table'
                                columns={columnsTable}
                                dataSource={data}
                                pagination={{ pageSize: 10 }}
                                locale={{
                                    emptyText: 'Sem processos de transferencia'
                                }}
                            />
                        </div>
                    }
                    {selectedMenu == 'hist' &&
                        <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                            <Table
                                size='small'
                                className='custom-table'
                                columns={columnsTableHist}
                                dataSource={dataHist}
                                pagination={{ pageSize: 10 }}
                                locale={{
                                    emptyText: 'Sem historico de processos de transferencia'
                                }}
                            />
                        </div>
                    }
                    <Modal
                        title={typeForm === 'Transferir' ? <p className='title'>Transferencia de Despesa</p> : <p className='title'>Editar Transferencia de Despesa</p>}
                        visible={modalApprove}
                        onCancel={() => setModalApprove(false)}
                        footer={null}
                        width={700}
                        centered
                    >
                        <Steps current={currentStep} className="mb-3">
                            <Steps.Step title="Dados Requisição" />
                            <Steps.Step title="Conclusão" />
                        </Steps>
                        {currentStep == 0 &&
                            <Form
                                form={form}
                                layout='vertical'
                                onFinish={handleSubmit}
                            >
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Tipo de Despesa"
                                        >
                                            <Input
                                                className='input-form'
                                                disabled
                                                value={expense?.expense?.expense?.codigo + '-' + expense?.expense?.expense?.nome}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Sector Requisitante"
                                        >
                                            <Input
                                                className='input-form'
                                                disabled
                                                value={expense?.expense?.sector}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Fornecedor"
                                        >
                                            <Input
                                                className='input-form'
                                                disabled
                                                value={expense?.expense?.supply?.name}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Valor"
                                        >
                                            <Input
                                                className='input-form'
                                                disabled
                                                value={numeral(expense?.expense?.value).format('0,0.00') + ' MZN'}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Metodo de Pagamento"
                                            name='method'
                                            required={true}
                                            rules={[{ required: true, message: 'Por favor, seleccione o método de pagamento!' }]}
                                        >
                                            <Select
                                                placeholder='Seleccione o método de pagamento'
                                                onChange={(e) => setMethod(e)}
                                                className='input-form'
                                            >
                                                <Option value="1">Cheque</Option>
                                                <Option value="2">Transferência Bancária</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {method != null &&
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                label="Banco"
                                                name="banco"
                                                required={true}
                                                rules={[{ required: true, message: 'Por favor, insira o nome do banco!' }]}
                                            >
                                                <Input
                                                    className='input-form'
                                                    disabled={method == 2 ? true : false}
                                                    placeholder='Nome do Banco'
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name="numero"
                                                label={method == 1 ? 'Número do Cheque' : 'NIB'}
                                                required={true}
                                                rules={[{ required: true, message: 'Por favor, insira o número!' }]}
                                            >
                                                <Input
                                                    className='input-form'
                                                    disabled={method == 2 ? true : false}
                                                    placeholder={method == 1 ? 'Número do Cheque' : 'NIB'}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                                <div className='flex gap-2 w-full justify-end'>
                                    {typeForm == 'Editar' &&
                                        <ReactToPrint
                                            trigger={() =>
                                                <Button
                                                    className='button-med'
                                                >
                                                    Imprimir Requisição
                                                </Button>
                                            }
                                            content={() => docRef.current}
                                            documentTitle={'Requisicao de Transferencia da Despesa ' + process?.expense?.reference}
                                        />
                                    }
                                    <Button className='button-in' htmlType='submit' loading={loading}>
                                        {typeForm == 'Transferir' ? 'Confirmar' : 'Editar'}
                                    </Button>
                                    <Button className='button-out' onClick={() => setModalApprove(false)}>
                                        Cancelar
                                    </Button>
                                </div>
                            </Form>
                        }
                        {currentStep === 1 && (<>
                            <Result
                                className='p-0 mt-4'
                                status="success"
                                title={<p className='title'>Requisição Emitida</p>}
                                subTitle={<p className='text'>A requisição foi emitida com sucesso</p>}
                                extra={[
                                    <div className="flex flex-row justify-center gap-3 mt-2">
                                        <Button className='button-out' onClick={() => setModalApprove(false)}>
                                            Terminar
                                        </Button>
                                        <ReactToPrint
                                            trigger={() =>
                                                <Button
                                                    className='button-in'
                                                >
                                                    Imprimir Requisição
                                                </Button>
                                            }
                                            content={() => docRef.current}
                                            documentTitle={'Requisicao de Transferencia da Despesa ' + process?.expense?.reference}
                                        />
                                    </div>
                                ]}
                            />


                        </>)}
                        <div className="hidden">
                            <ComponentToPrint ref={docRef}>
                                <ModeloRequisicao data={process} />
                            </ComponentToPrint>
                        </div>
                    </Modal>
                    <Modal
                        title={<p className='title'>Detalhes da Despesa</p>}
                        visible={modalView}
                        onCancel={() => setModalView(false)}
                        footer={null}
                        width={700}
                        centered
                    >
                        <ExpenseDetail dados={expense} />
                    </Modal>
                </div>
            </Spin>
        </div>
    );
}

export default TransferirDespesa;