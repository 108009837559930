import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaPlus, FaSchool, FaMoneyBillWave, FaMoneyBill } from 'react-icons/fa';
import { FiDelete, FiEdit, FiEye, FiTrendingDown, FiTrendingUp } from 'react-icons/fi';
import { Input, Button, Form, Table, Divider, Modal, Col, Select, Spin, Card, Statistic, Row, Tag, Descriptions, notification } from 'antd';
import { parse, set } from 'date-fns';
import { ca, tr } from 'date-fns/locale';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { getExpense, getExpenseParams, getExpenses, getSupplies, getUnitBalance } from '../../API/StaticGetters';
import ExpenseDetail from '../../Components/despesa/detail';
const { Option } = Select;

function ManageDespesa() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [form] = Form.useForm();
    const [modalApprove, setModalApprove] = useState(false)
    const [modalView, setModalView] = useState(false)
    const [typeForm, setTypeForm] = useState('')
    const [balance, setBalance] = useState()
    const [expense, setExpense] = useState()
    const [status, setStatus] = useState()
    const [type, setType] = useState()
    const [message, setMessage] = useState('')

    useEffect(() => {
        if (location.pathname.includes('aprovar')) {
            setStatus(1)
            setType('Aprovar')
            localStorage.setItem('title', 'Aprovação de Despesas')
            localStorage.setItem('type', '2');
            getData(1);
        } else if (location.pathname.includes('vistoria')) {
            setStatus(2)
            setType('Vistoria')
            localStorage.setItem('title', 'Vistoria de Despesas')
            localStorage.setItem('type', '2');
            getData(2);
        }else if (location.pathname.includes('autorizar')) {
            setStatus(3)
            setType('Autorizar')
            localStorage.setItem('title', 'Autorização de Despesas')
            localStorage.setItem('type', '2');
            getData(3);
        }
    }, [])

    const getData = async (type) => {
        setLoading(true)
        let exps = await getExpenses(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id, type)
        let bnl = await getUnitBalance(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id)
        setBalance(bnl)
        setData(exps)
        setLoading(false)
    }

    //Colunas da Tabela
    const columnsTable = [
        { title: 'Referencia', dataIndex: 'reference', align: 'left', width: 100, key: 'id' },
        { title: 'Rubrica', dataIndex: 'expense_type', align: 'left', width: 80, render: (text, record) => record?.expense?.codigo, key: 'expense_type' },
        { title: 'Descrição', dataIndex: 'expense_type', align: 'left', render: (text, record) => record?.expense?.nome, key: 'expense_type' },
        { title: 'Eixo/Objectivo', dataIndex: 'obj_estrategico', width: 80, render: (text, record) => record?.objective?.code, align: 'left', key: 'obj_estrategico' },
        { title: 'Data', dataIndex: 'conta', align: 'left', render: (text, record) => dayjs(record.created_at).format('DD/MM/YYYY'), width: 80, key: 'data' },
        { title: 'Valor', dataIndex: 'Valor', align: 'left', render: (text, record) => numeral(record.value).format('0,0.00') + ' MZN', width: 150, key: 'valor' },
        { title: 'Status', dataIndex: 'status', align: 'left', key: 'status', width: 120, render: (text, record) => <Tag color={record.status === 'Reprovado' ? 'red' : 'blue'}>{record.status.name}</Tag> },
        {
            title: 'Actions', align: 'left', width: 50, render: (text, record) =>
                <div className='flex gap-2'>
                    <Button style={{ backgroundColor: 'blue' }} className='text-white' size='small' icon={<FiEye />} onClick={() => goSee(record)}></Button>
                </div>
        }
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };


    //handles
    const goSee = async (record) => {
        let data = await getExpense(record.id)
        setExpense(data)
        setModalView(true)
    }

    const handleAprove = (type) => {
        return () => {
            form.resetFields();
            setMessage('')
            if (selectedRowKeys.length === 0) {
                notification.error({
                    message: 'Sem Despesas Selecionadas',
                    description: 'Selecione pelo menos uma despesa'
                });
                return;
            }
            switch (type) {
                case 1:
                    setTypeForm('Aprovar');
                    setModalApprove(true);
                    break;
                case 2:
                    setTypeForm('Reprovar');
                    setModalApprove(true);
                    break;
                default:
                    break;
            }
        }
    }

    const handleSubmit = async () => {
        setLoading(true)
        let stts = 0
        switch (type) {
            case 'Aprovar':
                stts = 2
                break;
            case 'Vistoria':
                stts = 3
                break;
            case 'Autorizar':
                stts = 4
                break;
            default:
                stts = 5
                break;
        }
        axios.put(
            localStorage.getItem('url') + '/api/expense/manage/',
            {
                ids: selectedRowKeys,
                action: typeForm === 'Aprovar' ? (type+" Despesa") : "Reprovou Despesa",
                status: stts,
                message: message
            },
            {
                headers: {
                    'Authorization': 'Token ' + sessionStorage.getItem('token')
                }
            },
        ).then(() => {
            notification.success({
                message: 'Despesas Atualizadas',
                description: 'Despesas atualizadas com sucesso'
            });
            getData(status)
            setModalApprove(false)
        }).catch(() => {
            notification.error({
                message: 'Erro ao Atualizar Despesas',
                description: 'Erro ao atualizar despesas, tente novamente'
            });
        }).finally(() => {
            setLoading(false)
            setSelectedRowKeys([])
        })
    }


    return (
        <div className='flex flex-col gap-4'>
            <Spin spinning={loading}>
                <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
                    <div className='w-full flex flex-wrap items-center mb-2 gap-5 justify-between grid-cols-3'>
                        <Col span={8} className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Unidade"
                                    value={balance?.unit?.name}
                                    valueStyle={{
                                        color: 'green',
                                    }}
                                    prefix={<FaSchool />}
                                />
                            </Card>
                        </Col>
                        <Col className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Saldo Disponível"
                                    value={balance?.balance}
                                    valueStyle={{
                                        color: 'green',
                                    }}
                                    suffix="MZN"
                                    prefix={<FaMoneyBillWave />}
                                />
                            </Card>
                        </Col>
                        <Col className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Saldo Pedente"
                                    value={balance?.pending}
                                    valueStyle={{
                                        color: 'BLUE',
                                    }}
                                    suffix="MZN"
                                    prefix={<FaMoneyBill />}
                                />
                            </Card>
                        </Col>
                    </div>
                    <Divider className='my-0' />
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <Table
                            size='small'
                            className='custom-table'
                            columns={columnsTable}
                            dataSource={data}
                            pagination={{ pageSize: 10 }}
                            rowKey={record => record.id}
                            rowSelection={rowSelection}
                            footer={() => {
                                return (
                                    <div className="flex flex-row justify-between items-center w-full">
                                        <p className="text" style={{ margin: 0 }}>{selectedRowKeys.length} Despesas Selecionados</p>
                                        <div className="flex flex-row justify-end gap-3 ">
                                            <Button
                                                className="button-in"
                                                onClick={handleAprove(1)}
                                            >
                                                Confirmar Selecionados
                                            </Button>
                                            <Button
                                                className="button-out"
                                                onClick={handleAprove(2)}
                                            >
                                                Reprovar Selecionados
                                            </Button>
                                        </div>
                                    </div>
                                )

                            }}
                            locale={{
                                emptyText: 'Sem processos de despesa'
                            }}
                        />
                    </div>
                    <Modal
                        title={typeForm === 'Aprovar' ? <p className='title'>{type} Despesas</p> : <p className='title'>Reprovar Despesas</p>}
                        visible={modalApprove}
                        onCancel={() => setModalApprove(false)}
                        footer={null}
                        width={400}
                        centered
                    >
                        {typeForm === 'Reprovar' && <>
                            <p>Tem a certeza que deseja reprovar?</p>
                            <Form
                                form={form}
                                layout='vertical'
                                scrollToFirstError
                                onFinish={handleSubmit}
                            >
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Motivo'
                                            name='reason'
                                            rules={[{ required: true, message: 'Por favor insira o motivo' }]}
                                        >
                                            <Input.TextArea
                                                className='input-form'
                                                rows={2}
                                                placeholder='Insira o motivo da reprovação'
                                                onChange={
                                                    (e) => setMessage(e.target.value)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='flex gap-2 w-full justify-end'>
                                    <Button className='button-in' htmlType='submit' loading={loading}>
                                        Confirmar
                                    </Button>
                                    <Button className='button-out' onClick={() => setModalApprove(false)}>
                                        Cancelar
                                    </Button>
                                </div>
                            </Form>
                        </>}
                        {typeForm === 'Aprovar' && <>
                            <p>Tem a certeza que deseja {type.toLowerCase()} a despesa?</p>
                            <div className='flex gap-2 w-full justify-end'>
                                <Button className='button-in' onClick={handleSubmit} loading={loading}>
                                    Confirmar
                                </Button>
                                <Button className='button-out' onClick={() => setModalApprove(false)}>
                                    Cancelar
                                </Button>
                            </div>
                        </>}
                    </Modal>
                    <Modal
                        title={<p className='title'>Detalhes da Despesa</p>}
                        visible={modalView}
                        onCancel={() => setModalView(false)}
                        footer={null}
                        width={700}
                        centered
                    >
                        <ExpenseDetail dados={expense} />
                    </Modal>
                </div>
            </Spin>
        </div>
    );
}

export default ManageDespesa;