import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaSchool, FaMoneyBillWave, FaMoneyBill } from 'react-icons/fa';
import { FiDelete, FiEdit, FiEye, FiTrendingDown, FiTrendingUp } from 'react-icons/fi';
import { Input, Button, Form, Table, Divider, Modal, Col, Select, Spin, Card, Statistic, Row, Tag, Descriptions, notification, InputNumber } from 'antd';
import { parse, set } from 'date-fns';
import { ca, tr } from 'date-fns/locale';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { getExpense, getExpenseParams, getExpenses, getSupplies, getUnitBalance } from '../../API/StaticGetters';
import ExpenseDetail from '../../Components/despesa/detail';
const { Option } = Select;

function RegistarDespesa() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [form] = Form.useForm();
    const [modalForm, setModalForm] = useState(false)
    const [modalApprove, setModalApprove] = useState(false)
    const [modalView, setModalView] = useState(false)
    const [typeForm, setTypeForm] = useState('')

    const [balance, setBalance] = useState()

    const [categories, setCategories] = useState([])
    const [rubrics, setRubrics] = useState([])
    const [eixo, setEixo] = useState([])
    const [objectives, setObjectives] = useState([])
    const [supplies, setSupplies] = useState([])

    const [selectedEixo, setSelectedEixo] = useState()
    const [selectedCategory, setSelectedCategory] = useState()

    const [expense, setExpense] = useState()

    useEffect(() => {
        localStorage.setItem('title', 'Registrar Despesas')
        localStorage.setItem('type', '2');
        getData();
    }, [])

    const getData = async () => {
        setLoading(true)
        let types = await getExpenseParams(1);
        let eixos = await getExpenseParams(2);
        let objs = await getExpenseParams(3);
        let sppl = await getSupplies()
        let exps = await getExpenses(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id, 'Tec')
        let bnl= await getUnitBalance(JSON.parse(sessionStorage.getItem('user'))?.unidade?.id)
        setBalance(bnl)
        setData(exps)
        splitCategoriesFromRubrics(types);
        setEixo(eixos)
        setObjectives(objs)
        setSupplies(sppl)
        setLoading(false)
    }

    const splitCategoriesFromRubrics = (data) => {
        let cats = [];
        data.forEach(item => {
            cats.push({ value: item.categoria });
        })
        cats = cats.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
        setCategories(cats);
        setRubrics(data);
    }

    //Colunas da Tabela
    const columnsTable = [
        { title: 'Referencia', dataIndex: 'reference', align: 'left', width: 100, key: 'id' },
        { title: 'Rubrica', dataIndex: 'expense_type', align: 'left', width: 80, render: (text, record) => record?.expense?.codigo, key: 'expense_type' },
        { title: 'Descrição', dataIndex: 'expense_type', align: 'left', render: (text, record) => record?.expense?.nome, key: 'expense_type' },
        { title: 'Eixo/Objectivo', dataIndex: 'obj_estrategico', width: 80, render: (text, record) => record?.objective?.code, align: 'left', key: 'obj_estrategico' },
        { title: 'Data', dataIndex: 'conta', align: 'left', render: (text, record) => dayjs(record.created_at).format('DD/MM/YYYY'), width: 80, key: 'data' },
        { title: 'Valor', dataIndex: 'Valor', align: 'left', render: (text, record) => numeral(record.value).format('0,0.00') + ' MZN', width: 150, key: 'valor' },
        { title: 'Status', dataIndex: 'status', align: 'left', key: 'status', width: 120, render: (text, record) => <Tag color={record.status === 'Reprovado' ? 'red' : 'blue'}>{record.status.name}</Tag> },
        {
            title: 'Actions', align: 'left', width: 100, render: (text, record) =>
                <div className='flex gap-2'>
                    <Button style={{backgroundColor:'blue'}} className='text-white' size='small' icon={<FiEye />} onClick={() => goSee(record)}></Button>
                    <Button style={{backgroundColor:'#047d4b'}} className='bg-green-500 text-white' size='small' icon={<FiEdit />} onClick={() => goEdit(record)}></Button>
                    <Button style={{backgroundColor:'#d6661b'}} className='bg-gray-500 text-white' size='small' icon={<FiDelete />} onClick={() => goDelete(record)}></Button>
                </div>
        }
    ];


    //handles
    const goSee = async(record) => {
        let data = await getExpense(record.id)
        setExpense(data)
        setModalView(true)
     }

    const goEdit = (record) => {
        setTypeForm('edit')
        setExpense(record)
        form.setFieldsValue({
            categoria: record?.expense?.categoria,
            eixo: record?.objective?.eixo?.id,
            rubrica: record?.expense?.codigo,
            obj: record?.objective?.id,
            fornecedor: record?.supply?.id,
            valor: record.value,
            detalhes: record.description,
            sector: record.sector
        })
        setSelectedCategory(record?.expense?.categoria)
        setSelectedEixo(record?.objective?.eixo?.id)
        setModalForm(true)
    }

    const goDelete = (record) => {
        setTypeForm('delete')
        setExpense(record)
        setModalApprove(true)
    }


    const handleAdd = () => {
        setTypeForm('add')
        form.resetFields()
        setSelectedCategory('')
        setSelectedEixo('')
        setModalForm(!modalForm)
    }

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            await axios({
                method: "post",
                url: `${localStorage.getItem('url')}/api/expense/`,
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                data: {
                    unit_id: JSON.parse(sessionStorage.getItem('user'))?.unidade?.code,
                    code: values.rubrica,
                    objective_id: values.obj,
                    supply_id: values.fornecedor,
                    value: parseFloat(values.valor),
                    description: values.detalhes,
                    sector: values.sector,
                }
            }).then(d => {
                notification.success({
                    message: 'Sucesso',
                    description: 'A Despesa foi registada com sucesso',
                });
                setModalForm(false);
                getData();

            }).catch(e => {
                notification.error({
                    message: 'Erro',
                    description: e.response.data.message
                });
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    const handleSubmitEdit = async (values) => {
        try {
            setLoading(true);
            await axios({
                method: "put",
                url: `${localStorage.getItem('url')}/api/expense/`,
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                data: {
                    id: expense.id,
                    code: values.rubrica,
                    objective_id: values.obj,
                    supply_id: values.fornecedor,
                    value: parseFloat(values.valor),
                    description: values.detalhes,
                    sector: values.sector,
                    status: 1,
                    action:'Actualizou a despesa',
                    message:'',
                }
            }).then(d => {
                notification.success({
                    message: 'Sucesso',
                    description: 'A Despesa foi actualizada com sucesso',
                });
                setModalForm(false);
                getData();
            }).catch(e => {
                notification.error({
                    message: 'Erro',
                    description: e.response.data.message
                });
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = async () => {
        try {
            setLoading(true);
            await axios({
                method: "delete",
                url: `${localStorage.getItem('url')}/api/expense/`,
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}`, 'Content-Type': 'application/json' },
                data: {
                    id: expense.id,
                    action:'Cancelou a despesa',
                    message:'Processo cancelado',
                }
            }).then(d => {
                notification.success({
                    message: 'Sucesso',
                    description: 'A Despesa foi cancelada com sucesso',
                });
                setModalForm(false);
                getData();
            }).catch(e => {
                notification.error({
                    message: 'Erro',
                    description: e.response.data.message
                });
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='flex flex-col gap-4'>
            <Spin spinning={loading}>
                <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
                    <div className='w-full flex flex-wrap items-center mb-2 gap-5 justify-between grid-cols-3'>
                        <Col span={8} className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Unidade"
                                    value={balance?.unit?.name}
                                    valueStyle={{
                                        color: 'green',
                                    }}
                                    prefix={<FaSchool />}
                                />
                            </Card>
                        </Col>
                        <Col className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Saldo Disponível"
                                    value={balance?.balance}
                                    valueStyle={{
                                        color: 'green',
                                    }}
                                    suffix="MZN"
                                    prefix={<FaMoneyBillWave />}
                                />
                            </Card>
                        </Col>
                        <Col className="flex-grow">
                            <Card size='small' bordered={false} className='w-full'>
                                <Statistic
                                    title="Saldo Pedente"
                                    value={balance?.pending}
                                    valueStyle={{
                                        color: 'BLUE',
                                    }}
                                    suffix="MZN"
                                    prefix={<FaMoneyBill />}
                                />
                            </Card>
                        </Col>
                    </div>
                    <Divider className='my-0' />
                    <Button
                        className='button-in'
                        onClick={handleAdd}
                        icon={<FaPlus />}
                        size='medium'
                    >
                        Requisitar Despesa
                    </Button>
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <Table
                            size='small'
                            className='custom-table'
                            columns={columnsTable}
                            dataSource={data}
                            pagination={{ pageSize: 10 }}
                            locale={{
                                emptyText: 'Sem processos de despesa'
                            }}
                        />
                    </div>
                    <Modal
                        title={typeForm === 'add' ? <p className='title'>Registar Despesa</p> : <p className='title'>Editar Despesa</p>}
                        visible={modalForm}
                        onCancel={() => setModalForm(false)}
                        footer={null}
                        width={800}
                    >
                        <Form
                            form={form}
                            layout='vertical'
                            scrollToFirstError
                            onFinish={typeForm === 'add' ? handleSubmit : handleSubmitEdit}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="categoria"
                                        label="Categoria"
                                        rules={[{ required: true, message: 'Por favor seleccione a categoria' }]}
                                    >
                                        <Select
                                            className='input-form'
                                            placeholder="Seleccione a Categoria"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(value) => setSelectedCategory(value)}
                                        >
                                            {categories.map((item, index) => (
                                                <Option key={index} value={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="rubrica"
                                        label="Rubrica"
                                        rules={[{ required: true, message: 'Por favor seleccione a rubrica' }]}
                                    >
                                        <Select
                                            className='input-form'
                                            placeholder="Seleccione a Rubrica"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {rubrics.filter(item => item.categoria === selectedCategory).map((item, index) => (
                                                <Option key={index} value={item.codigo}>{item.codigo}-{item.nome}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="eixo"
                                        label="Eixo"
                                        rules={[{ required: true, message: 'Por favor seleccione o eixo' }]}
                                    >
                                        <Select
                                            className='input-form'
                                            placeholder="Seleccione o Eixo"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(value) => setSelectedEixo(value)}
                                        >
                                            {eixo.map((item, index) => (
                                                <Option key={index} value={item.id}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="obj"
                                        label="Objectivo"
                                        rules={[{ required: true, message: 'Por favor seleccione o objectivo' }]}
                                    >
                                        <Select
                                            className='input-form'
                                            placeholder="Seleccione o Objectivo"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {objectives.filter(item => item?.eixo?.id === selectedEixo).map((item, index) => (
                                                <Option key={index} value={item.id}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="sector"
                                        label="Sector Requisitante"
                                        rules={[{ required: true, message: 'Por favor seleccione o sector requisitante' }]}
                                    >
                                        <Input className='input-form' placeholder='Digite o sector requisitante' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="fornecedor"
                                        label="Fornecedor"
                                        rules={[{ required: true, message: 'Por favor seleccione o fornecedor' }]}
                                    >
                                        <Select
                                            className='input-form'
                                            placeholder="Seleccione o Fornecedor"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {supplies.map((item, index) => (
                                                <Option key={index} value={item.id}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="valor"
                                        label="Valor"
                                        rules={[{ required: true, message: 'Por favor seleccione o valor' },
                                    {
                                        validator: async (_, value) => {
                                            if ((parseFloat(value)+parseFloat(balance?.pending)) > parseFloat(balance?.balance)) {
                                                return Promise.reject('O valor da despesa é superior ao saldo disponível');
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                    ]}
                                    >
                                        <InputNumber
                                            className='input-form'
                                            placeholder='Digite o valor'
                                            style={{ width: '100%' }}
                                            formatter={value => `MZN ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\MZN\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                name="detalhes"
                                label="Descrição"
                                rules={[{ required: true, message: 'Por favor digite a Descrição da Despesa' }]}
                            >
                                <Input.TextArea className='input-form' placeholder='Digite os detalhes' />
                            </Form.Item>
                            <div className='flex gap-2 w-full justify-end'>
                                <Button className='button-in' loading={loading} htmlType='submit'>
                                    Registar
                                </Button>
                                <Button className='button-out' onClick={() => setModalForm(false)}>
                                    Cancelar
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                    <Modal
                        title={<p className='title'>Cancelar Despesa</p>}
                        visible={modalApprove}
                        onCancel={() => setModalApprove(false)}
                        footer={null}
                        width={700}
                        centered
                    >
                        {typeForm === 'delete' && <>
                            <p>Tem a certeza que deseja Cancelar a despesa?</p>
                            <div className='flex gap-2 w-full'>
                                <Button className='ml-auto bg-red-500 text-white' onClick={handleDelete} loading={loading}>
                                    Sim
                                </Button>
                                <Button className='bg-green-500 text-white' onClick={() => setModalApprove(false)}>
                                    Não
                                </Button>
                            </div>
                        </>}
                    </Modal>
                    <Modal
                        title={<p className='title'>Detalhes da Despesa</p>}
                        visible={modalView}
                        onCancel={() => setModalView(false)}
                        footer={null}
                        width={700}
                        centered
                    >
                        <ExpenseDetail dados={expense} />
                    </Modal>
                </div>
            </Spin>
        </div>
    );
}

export default RegistarDespesa;